import Vue from "vue";

/*TITLE*/
document.title = "Residencial Laderas de Parquesol | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Laderas de Parquesol";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Laderas de Parquesol";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-bremen-roble-193x120--20230113040100.jpg";
Vue.prototype.$image_kitchen = "cocina-bremen-nut-193x120-glaciar-316x90-banco-nubol-bottega-antracita--20230113040126.jpg";
Vue.prototype.$image_bath1 = "banop-bremen-roble-urban-natural-nature-cubik-urban-natural-nature--20230113040157.jpg";
Vue.prototype.$image_bath2 = "banosec-bremen-nut-urban-natural-nature-deco-bremen-nut_vertical--20230113040127.jpg";
Vue.prototype.$image_room = "salon-bremen-roble-193x120--20230113040154.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-bremen-roble-193x120--20230113040100.jpg",
  },
  {
    src: "salon-bremen-nut-193x120--20230113040106.jpg",
  },
  {
    src: "salon-bremen-natural-193x120--20230113040112.jpg",
  },
  {
    src: "salon-bremen-ash-193x120--20230113040119.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-bremen-nut-193x120-glaciar-316x90-banco-nubol-bottega-antracita--20230113040126.jpg",
  },
  {
    src: "cocina-bremen-natural-193x120-crystal-white-316x90-cool-bottega-acero--20230113040133.jpg",
  },
  {
    src: "cocina-bremen-ash-193x120-glaciar-316x90-clear-bottega-antracita--20230113040140.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-bremen-roble-urban-natural-nature-cubik-urban-natural-nature--20230113040157.jpg",
  },
  {
    src: "formato-3d-acabados--20230113040117.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-bremen-nut-urban-natural-nature-deco-bremen-nut_vertical--20230113040127.jpg",
  },
  {
    src: "banosec-bremen-natural-matika-acero-stripe-matika-acero--20230113040133.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "salon-bremen-roble-193x120--20230113040154.jpg",
  },
  {
    src: "salon-bremen-nut-193x120--20230113040100.jpg",
  },
  {
    src: "salon-bremen-natural-193x120--20230113040106.jpg",
  },
  {
    src: "salon-bremen-ash-193x120--20230113040113.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LADERAS_DE_PARQUESOL/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/805949311?h=0cdeb5321a";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20ciudad%20de%20la%20habana%20calle%20siervas%20de%20jesús%2047013%20valladolid",
    text: "Calle Ciudad de la Habana / Calle Siervas de Jesús, 47013 Valladolid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20mallorca%204%206%20local%20comercial%2047013%20valladolid",
    text: "Calle Mallorca 4-6 Local Comercial, 47013 Valladolid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:983525166",
    text: "983525166",
  },
  {
    icon: "mdi-email",
    link: "mailto:informacion@cavidel.es",
    text: "informacion@cavidel.es",
  },
];
